.form__container {
  background-color: #333;
}

.extended__btn {
  padding: 0.6rem 3rem;
}

.extend__form__container__width {
  min-width: 550px;
}

.token__symbol {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 700 !important;
  font-size: 1rem !important;
}

.custom-font-extended {
  color: #3f3d3d !important;
}



/* .lg__checkbox {
  width: 30px;
  height: 30px;
} */
