.dot {
    width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
  }
  
  .stake__logo img {
    max-width: 90px;
    max-height: 90px;
  }
  
  .stake__progress {
    height: 8px;
  }
  
  .stake__card{
    background-color:  #2e2e2e !important;
  }


  