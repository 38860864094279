.form__container {
  background-color: #333;
}

.extended__btn {
  padding: 0.6rem 3rem;
}

.extend__form__container__width {
  min-width: 550px;
}

.pretty .state label:before {
  border-color: #252a2f !important;
}


